@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';


@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

p {
  font-size: rem-calc(17px);
}
.head {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin-bottom: 1.5em;
  margin-top: 0.25em;
  margin-left: auto;
  margin-right: auto;
}
.stores {
  text-align: center;
  @include breakpoint(medium) {
    text-align: left;
  }
}
.store {
  //min-height: 32px;
  max-height: 40px;
}
.social-icons {
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  display: block;
  text-align: center;
  @include breakpoint(medium) {
    text-align: right;
  }
}
.no-padding {
  padding: 0;
}
.linksRow {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  max-width: 550px;
}

.coverImage {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @extend .padding-left-right-27;
}

.logo-with-slogan {
  float: left;
  width: 500px;

  @include breakpoint(medium) {
    text-align: center;
    width: 100%;
  }
}

.leftRightRadius {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  padding-left: 18px !important;
}

.bottomSpacing8Px {
  margin-bottom: 20px;
  padding-bottom: 0;
}

.subscribeRow {
  height: 100%;
  padding-top: 18px;
  padding-bottom: 10px;
  margin-bottom: 60px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: initial;
  //background-color: #ffffff;
}
.alert-box {
  text-align: center !important;

}

#logo-small {
  height: 40px;
  margin-top: -30px;
}

#footerLogo {
  height: auto;
  color: white;
  a {
    color: white;
  }
}

.social-icon {

  margin-top: 8px;
  margin-left: 8px;
}

.comingSoon {
  color: #c8c8c8;
  @extend h2;
  margin-left: 12px;

  font-size: 30px;
  display: inline-block;
  @include breakpoint(medium) {
    margin-top: 8px;
    display: block;
    text-align: center;
  }
  margin-bottom: 0;
}

.padding-left-right-27 {
  padding-right: 27px;
  padding-left: 27px;
}

.combinationText {
  @extend p;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: map-get(map-get($header-sizes, "medium"), "h4");
}
.privacy {
  padding-top: 3em;
  padding-bottom: 3em;
  a {
    color: $white;
    text-decoration: underline;
  }
}
#combinationText {
  font-family: "Capriola", sans-serif;
  font-weight: 300;
  font-style: normal;
}

#combinationTextMore {
  font-size: rem-calc(30);
}

#numCombinations {
  font-size: rem-calc(51);
  line-height: 60%;
}

#combinationTextCombinations {
  font-size: rem-calc(24);
}

#footerBackground2, #footerBackground {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-position: center;
  background-size: 1420px, 1144px;
  height: 1144px;
}

.footer {
  margin-top: 720px;
}

#container {
  min-height: 100%;
  position: relative;
}

#body {
  padding-bottom: 1146px;
}

.copyright {
  @extend p;
  margin-bottom: 0;
  margin-top: auto;
  font-size: rem-calc(14);
  color: $white;
  a {
    color: $white;
    text-decoration: underline;
  }
}

.featureDescription {
  height: 100%;
}

.features {
  @include breakpoint(small) {
    margin-bottom: rem-calc(80px);
  }
}

.margin-top10 {
  display: block;
  margin-top: 16px;
}

.combinationNum {
  @extend h1;
  line-height: 100%;
  margin: 0;
}
.text-center {
  text-align: center;
}

.maxWidth {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  @extend .padding-left-right-27;
}
.decription {
  @extend .padding-left-right-27;
}

.promo {
  @extend .padding-left-right-27;
}

.slogan {
  margin-top: 40px;
}



#character {
  margin-top: 50px;
  height: 250px;
  margin-bottom: 180px;
}

.logo {
  background-image: url('assets/img/logo.png');
  width: 64px;
  height: 36px;
  margin-left: -2px;
  float: left;
}

#logo-big {
  margin-bottom: 20px;
}

.buttons {
  text-align: center;
  height: auto;
  width: 278px;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
}

#bottom-foot {
  margin-top: auto;
  margin-bottom: 0;
  padding: 0;
}
.icon-sized {
  height: 128px;
  width: 128px;
}
.icon {
  height: 128px;
  margin-top: rem-calc(24px);
  margin-bottom: rem-calc(24px);
}

#char {
  height: 344px;
  width: 310px;
}
